import React from "react"

import Layout from "../components/layout"


const ServicePage = () => (
  <Layout title="Palvelut">

    <div className="mx-auto col-xl-5 col-lg-6 col-md-7 col-sm-8 col-xs-12">

      <h2> Unseen architecture </h2>

      <p> Aisto luo kuvia tulevasta. Herätämme projektisi eloon selkeillä ja uniikeilla 3D-visualisoinneilla, jotka pohjautuvat harmoniaan ja aitoon tunnelmaan. Realistiset materiaalit ja valo-olosuhteet viimeistelevät toiveiden mukaisen kokonaisuuden. Luonnollisuuteen, tasapainoon ja autenttisuuteen perustuvat kuvat saavat kohteesi erottumaan joukosta. Tee ennennäkemättömästä todellisuutta. </p>

      <h3> Sisänäkymät </h3>
      <p> 
      Näkymät sisältä stailauksineen niin kerrostaloihin, pientaloihin kuin julkisiin rakennuksiin ja kaikkeen siltä väliltä. Myös olemassa olevien tilojen digitaalinen stailaus, eli tilan digitaalinen sisustaminen suunnittelun avuksi tai markkinointiin.
      </p>

      <h3> Ulkonäkymät </h3>
      <p> Kohteen visualisointi ulkoa pihapiireineen, mahdollista luoda täysin mallintamalla tai upottamalla mallinnettu rakennus valokuvaan.
      </p>
      <h3> 360-visualisointi ja VR </h3>
      <p>
      360°-kuvien avulla on mahdollista luoda selkeä kuva tulevasta kohteesta. Panoraamakuva, etenkin yhdistettynä VR-laseihin, antaa todenmukaisen ja uuden tavan päästä käsiksi uudiskohteen tunnelmaan.
      </p>

      <p> Kiinnostuitko 3D-visualisoinneista? </p>
      <b>Ota yhteyttä!  </b>
    </div>
  </Layout>
)

export default ServicePage
